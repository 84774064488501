import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import propertyDataService from "../services/property.service";
import Property from "./Property";

export default function Section1() {
  const [properties, setProperties] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await propertyDataService.getAll();
      // const response = await MyAPI.getData(someId);
      setProperties(response.data);
    }
    fetchData();
  }, []);

  // console.log(properties);

  return (
    <div className="section">
      <div className="container">
        <div className="row mb-5 align-items-center">
          <div className="col-lg-6">
            <h2 className="font-weight-bold text-primary">
              Popular Houses
            </h2>
          </div>
          <div className="col-lg-6 text-lg-end py-3">
            <span>
              <Link
                to={"/home"}
                className="btn-default btn-default-primary text-white py-3 px-4"
              >
                View all houses
              </Link>
            </span>
          </div>
        </div>
        <div className="row p-2">  
          {properties ? 
            <>{properties.map(property => 
           <Property property={property} key={property.id}/>
       
          )}</>
            : <p>Loading...</p>
            }

          {/* <PropertyItem property={img_1} /> */}
          {/* <div className="property-item col-sm-12 col-md-3 mb-3">
            <a href="property-single.html" className="img">
              <img src={img_8} alt="Property" className="img-fluid" />
            </a>
            <div className="property-content">
              <div className="price mb-2">
                <span>Ksh 1,100,000</span>
              </div>
              <div>
                <span className="d-block mb-2 text-black-50">
                  1300 Mugumo Lane, Lenana. 21BC
                </span>
                <span className="city d-block mb-3">Lenana, Nairobi</span>
                <div className="specs d-flex mb-4">
                  <span className="d-block d-flex align-items-center me-3">
                    <FontAwesomeIcon icon={faBed} />
                    <span className="caption mx-1">2 beds</span>
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <FontAwesomeIcon icon={faBath} />
                    <span className="caption mx-1">2 baths</span>
                  </span>
                </div>
                <a
                  href="property-single.html"
                  className="btn-default btn-default-primary py-2 px-3"
                >
                  See details
                </a>
              </div>
            </div>
          </div> */}
          {/* <div className="property-item col-sm-12 col-md-3 mb-3">
            <a href="property-single.html" className="img">
              <img src={img_2} alt="Property" className="img-fluid" />
            </a>
            <div className="property-content">
              <div className="price mb-2">
                <span>Ksh 2,500,000</span>
              </div>
              <div>
                <span className="d-block mb-2 text-black-50">
                  3210 Muruma Drive, Lenana. 11BC
                </span>
                <span className="city d-block mb-3">Lenana, Nairobi</span>
                <div className="specs d-flex mb-4">
                  <span className="d-block d-flex align-items-center me-3">
                    <FontAwesomeIcon icon={faBed} />
                    <span className="caption mx-1">3 beds</span>
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <FontAwesomeIcon icon={faBath} />
                    <span className="caption mx-1">3 baths</span>
                  </span>
                </div>
                <a
                  href="property-single.html"
                  className="btn-default btn-default-primary py-2 px-3"
                >
                  See details
                </a>
              </div>
            </div>
          </div>
          <div className="property-item col-sm-12 col-md-3 mb-3">
            <a href="property-single.html" className="img">
              <img src={img_3} alt="Property" className="img-fluid" />
            </a>
            <div className="property-content">
              <div className="price mb-2">
                <span>Ksh 5,200,000</span>
              </div>
              <div>
                <span className="d-block mb-2 text-black-50">
                  150 Oloiboni Avenue, Mosop.
                </span>
                <span className="city d-block mb-3">Kilimani, Nairobi</span>
                <div className="specs d-flex mb-4">
                  <span className="d-block d-flex align-items-center me-3">
                    <FontAwesomeIcon icon={faBed} />
                    <span className="caption mx-1">4 beds</span>
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <FontAwesomeIcon icon={faBath} />
                    <span className="caption mx-1">5 baths</span>
                  </span>
                </div>
                <a
                  href="property-single.html"
                  className="btn-default btn-default-primary py-2 px-3"
                >
                  See details
                </a>
              </div>
            </div>
          </div>
          <div className="property-item col-sm-12 col-md-3 mb-3">
            <a href="property-single.html" className="img">
              <img src={img_4} alt="Property" className="img-fluid" />
            </a>
            <div className="property-content">
              <div className="price mb-2">
                <span>Ksh 1,300,000</span>
              </div>
              <div>
                <span className="d-block mb-2 text-black-50">
                  123 Acacia Avenue, Lavington
                </span>
                <span className="city d-block mb-3">Lenana, Nairobi</span>
                <div className="specs d-flex mb-4">
                  <span className="d-block d-flex align-items-center me-3">
                    <FontAwesomeIcon icon={faBed} />
                    <span className="caption mx-1">2 beds</span>
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <FontAwesomeIcon icon={faBath} />
                    <span className="caption mx-1">2 baths</span>
                  </span>
                </div>
                <a
                  href="property-single.html"
                  className="btn-default btn-default-primary py-2 px-3"
                >
                  See details
                </a>
              </div>
            </div>
          </div>
          <div className="property-item col-sm-12 col-md-3 mb-3">
            <a href="property-single.html" className="img">
              <img src={img_5} alt="Property" className="img-fluid" />
            </a>
            <div className="property-content">
              <div className="price mb-2">
                <span>Ksh 1,150,000</span>
              </div>
              <div>
                <span className="d-block mb-2 text-black-50">
                  234 Lavish Lane, Lavington
                </span>
                <span className="city d-block mb-3">Lenana, Nairobi</span>
                <div className="specs d-flex mb-4">
                  <span className="d-block d-flex align-items-center me-3">
                    <FontAwesomeIcon icon={faBed} />
                    <span className="caption mx-1">2 beds</span>
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <FontAwesomeIcon icon={faBath} />
                    <span className="caption mx-1">2 baths</span>
                  </span>
                </div>
                <a
                  href="property-single.html"
                  className="btn-default btn-default-primary py-2 px-3"
                >
                  See details
                </a>
              </div>
            </div>
          </div>
          <div className="property-item col-sm-12 col-md-3 mb-3">
            <a href="property-single.html" className="img">
              <img src={img_6} alt="Property" className="img-fluid" />
            </a>
            <div className="property-content">
              <div className="price mb-2">
                <span>Ksh 1,590,000</span>
              </div>
              <div>
                <span className="d-block mb-2 text-black-50">
                  345 Serena Street, Lavington
                </span>
                <span className="city d-block mb-3">Lenana, Nairobi</span>
                <div className="specs d-flex mb-4">
                  <span className="d-block d-flex align-items-center me-3">
                    <FontAwesomeIcon icon={faBed} />
                    <span className="caption mx-1">2 beds</span>
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <FontAwesomeIcon icon={faBath} />
                    <span className="caption mx-1">2 baths</span>
                  </span>
                </div>
                <a
                  href="property-single.html"
                  className="btn-default btn-default-primary py-2 px-3"
                >
                  See details
                </a>
              </div>
            </div>
          </div>
          <div className="property-item col-sm-12 col-md-3 mb-3">
            <a href="property-single.html" className="img">
              <img src={img_7} alt="Property" className="img-fluid" />
            </a>
            <div className="property-content">
              <div className="price mb-2">
                <span>Ksh 2,000,000</span>
              </div>
              <div>
                <span className="d-block mb-2 text-black-50">
                  567 Ivy Crescent, Lavington
                </span>
                <span className="city d-block mb-3">Lenana, Nairobi</span>
                <div className="specs d-flex mb-4">
                  <span className="d-block d-flex align-items-center me-3">
                    <FontAwesomeIcon icon={faBed} />
                    <span className="caption mx-1">3 beds</span>
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <FontAwesomeIcon icon={faBath} />
                    <span className="caption mx-1">3 baths</span>
                  </span>
                </div>
                <a
                  href="property-single.html"
                  className="btn-default btn-default-primary py-2 px-3"
                >
                  See details
                </a>
              </div>
            </div>
          </div> */}

        </div>
        {/* <div className="row border">
          <div className="col-12">
            <div className="property-slider-wrap">
              <div className="property-slider">
                <PropertyItem property={img_1} />
              </div>
              <div
                id="property-nav"
                className="controls border block"
                tabIndex={0}
                aria-label="Carousel Navigation"
              >
                <span
                  className="prev"
                  data-controls="prev"
                  aria-controls="property"
                  tabIndex={-1}
                >
                  Prev
                </span>
                <span
                  className="next"
                  data-controls="next"
                  aria-controls="property"
                  tabIndex={-1}
                >
                  Next
                </span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
