import { Link } from "react-router-dom";
import person_1 from "../assets/images/alexis-chloe-hGf62gWyhJE-unsplash.jpg";
import person_2 from "../assets/images/etty-fidele-AzVexpHvuKY-unsplash.jpg";
import person_3 from "../assets/images/joshua-oluwagbemiga-8qFarlWZY-U-unsplash.jpg";

export default function Section6() {
  return (
    <div className="section section-5 bg-light">
          <div className="container">
            <div className="row justify-content-center text-center mb-5">
              <div className="col-lg-6 mb-5">
                <h2 className="font-weight-bold heading text-primary mb-4">
                  Our Agents
                </h2>
                <p className="text-black-50">
                Discover the Difference with Our Real Estate Agents.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div className="h-100 person">
                  <img src={person_1} alt="Property" className="img-fluid" />
                  <div className="person-contents">
                    <h2 className="mb-0"><Link to="">Esther Muriuki</Link></h2>
                    <span className="meta d-block mb-3">Real Estate Agent</span>
                    <p>
                    Your trusted partner in rentals. Our passionate real estate agent specializes in helping renters find their next home. With a deep understanding of local market trends, we provide personalized service, tailored recommendations, and expert negotiation. Your rental journey is in capable hands.
                    </p>
                    <ul className="social list-unstyled list-inline dark-hover">
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-twitter"><i className="bi bi-twitter-x"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-facebook"><i className="bi bi-facebook"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-linkedin"><i className="bi bi-linkedin"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-instagram"><i className="bi bi-instagram"></i></span></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div className="h-100 person">
                  <img src={person_2} alt="Property" className="img-fluid" />
                  <div className="person-contents">
                    <h2 className="mb-0"><Link to="">Wanjiru Mutisya</Link></h2>
                    <span className="meta d-block mb-3">Real Estate Agent</span>
                    <p>
                    Discover the difference with our professional real estate agent. Known for exceptional service and attention to detail, our agent is your trusted resource in the competitive rental market. We handle everything from pre-screening listings to lease agreements, ensuring a seamless and stress-free rental experience.
                    </p>
                    <ul className="social list-unstyled list-inline dark-hover">
                    <li className="list-inline-item">
                        <Link to=""><span className="icon-twitter"><i className="bi bi-twitter-x"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-facebook"><i className="bi bi-facebook"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-linkedin"><i className="bi bi-linkedin"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-instagram"><i className="bi bi-instagram"></i></span></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div className="h-100 person">
                  <img src={person_3} alt="Property" className="img-fluid" />
                  <div className="person-contents">
                    <h2 className="mb-0"><Link to="">Patrick Terer</Link></h2>
                    <span className="meta d-block mb-3">Real Estate Agent</span>
                    <p>
                    Searching for the ideal rental property? Our experienced real estate agent is here to guide you. With in-depth knowledge of the local rental market, our agent can help you find the perfect rental that suits your needs and budget. From property tours to lease negotiations, we make your rental journey smooth and hassle-free.
                    </p>
                    <ul className="social list-unstyled list-inline dark-hover">
                    <li className="list-inline-item">
                        <Link to=""><span className="icon-twitter"><i className="bi bi-twitter-x"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-facebook"><i className="bi bi-facebook"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-linkedin"><i className="bi bi-linkedin"></i></span></Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to=""><span className="icon-instagram"><i className="bi bi-instagram"></i></span></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}
