import { Link } from "react-router-dom";

let Footer = () => {
    return (
        <div className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="widget">
                  <h3>Contact</h3>
                  <address>23rd Street Spring Road</address>
                  <ul className="list-unstyled links">
                    <li><a href="tel://25400010101">+254(00)-010-0101</a></li>
                    <li><a href="tel://25400010102">+254(00)-010-0102</a></li>
                    <li>
                      <a href="mailto:info@kayatrue.com">info@kayatrue.com</a>
                    </li>
                  </ul>
                </div>
                {/* /.widget */}
              </div>
              {/* /.col-lg-4 */}
              <div className="col-lg-4">
                <div className="widget">
                  <h3>Sources</h3>
                  <ul className="list-unstyled float-start links">
                    <li><Link to="">About us</Link></li>
                    <li><Link to="">Services</Link></li>
                    {/* <li><Link to="">Vision</Link></li>
                    <li><Link to="">Mission</Link></li> */}
                    {/* <li><Link to="">Terms</Link></li>
                    <li><Link to="">Privacy</Link></li> */}
                  </ul>
                  <ul className="list-unstyled float-start links">
                    {/* <li><Link to="">Partners</Link></li>
                    <li><Link to="">Business</Link></li>
                    <li><Link to="">Careers</Link></li> */}
                    <li><Link to="">Blog</Link></li>
                    <li><Link to="">FAQ</Link></li>
                    {/* <li><Link to="">Creative</Link></li> */}
                  </ul>
                </div>
                {/* /.widget */}
              </div>
              {/* /.col-lg-4 */}
              <div className="col-lg-4">
                <div className="widget">
                  <h3>Links</h3>
                  <ul className="list-unstyled links">
                    {/* <li><Link to="">Our Vision</Link></li>
                    <li><Link to="">About us</Link></li> */}
                    {/* <li><Link to="">Contact us</Link></li> */}
                  </ul>
                  <ul className="list-unstyled social">
                    <li className="mx-1">
                      <Link to=""><span className="icon-instagram"><i className="bi bi-instagram"></i></span></Link>
                    </li>
                    <li  className="mx-1">
                      <Link to=""><span className="icon-twitter"><i className="bi bi-twitter-x"></i></span></Link>
                      
                    </li>
                    <li  className="mx-1">
                      <Link to=""><span className="icon-facebook"><i className="bi bi-facebook"></i></span></Link>
                    </li>
                    <li  className="mx-1">
                      <Link to=""><span className="icon-linkedin"><i className="bi bi-linkedin"></i></span></Link>
                    </li>
                    <li  className="mx-1">
                      <Link to=""><span className="icon-pinterest"><i className="bi bi-pinterest"></i></span></Link>
                    </li>
                    <li  className="mx-1">
                      <Link to=""><span className="icon-dribbble"><i className="bi bi-dribbble"></i></span></Link>
                    </li>
                    <li  className="mx-1">
                      <Link to=""><span className="icon-youtube"><i className="bi bi-youtube"></i></span></Link>
                    </li>
                  </ul>
                </div>
                {/* /.widget */}
              </div>
              {/* /.col-lg-4 */}
            </div>
            {/* /.row */}
            <div className="row mt-5">
              <div className="col-12 text-center">
                {/* 
              **==========
              NOTE: 
              Please don't remove this copyright link unless you buy the license here https://untree.co/license/  
              **==========
            */}
                <p>
                  Kayatrue © 2024. All Rights Reserved. 
                </p>
                <div>
                  <Link to="" className="text-decoration-none">Privacy Policy</Link> | <Link to="" className="text-decoration-none">Terms of Use</Link>
                </div>
              </div>
            </div>
          </div>
          {/* /.container */}
        </div>
    )
}

export default Footer;