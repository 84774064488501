import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

// sections
import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import Section5 from "../components/Section5";
import Section6 from "../components/Section6";

let Landing = () => {
  return (
    <>
      <Navbar />
      <div className="hero">
        <div className="hero-slide">
          <div
            className="img overlay"
            style={{
              backgroundImage:
                'url("https://images.unsplash.com/photo-1666009373373-d172cf109462?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80")',
            }}
          />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9 text-center">
              <h1 className="heading" data-aos="fade-up">
                Easiest way to find your dream home
              </h1>
              <form
                action="_"
                className="narrow-w form-search d-flex align-items-stretch mb-3"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <input
                  type="text"
                  className="form-control px-4"
                  placeholder="Your preferred location e.g. Kilimani"
                />
                <Link to={"/home"} className="btn-default btn-default-primary ">
                  Search
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Footer />
    </>
  );
};

export default Landing;
